<script>
import { defineComponent } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import DefaultEditProfile from '/~/components/ewallet-menu/views/profile-settings/profile-edit-profile-view.vue'

export default defineComponent({
  components: {
    BaseAsidePage,
  },
  extends: DefaultEditProfile,
})
</script>
